import React from 'react';
import axios from 'axios';
import ProfilePopUp from '../Components/ProfilePopUp';

const Header = ({
  profilePopUpForm,
  profileDropdownHandle,
  profileDropdown,
  profilePopUpFormHandle,
  certificateDropdown,
  certificateDropdownHandle,
  masterData,
  profileDetails,
  userCountry,
  inputChange,
  postProfileData,
  editEnable,
  inputDisable,
  inputError,
  onLogout,
}) => {
  return (
    <React.Fragment>
      <div className="header" style={{minHeight:150}}>
        <div className="brandLogo">
          <img src="/images/logo.png" style={{maxWidth:210}} className="img-fluid mt-3" alt="" />
        </div>
        <div className="currentStatusRankingWrp">
          <h3>Current Status ranking</h3>
          <div className="currentStatusRanking">
            <div
              className="currentStatusPin"
              style={{ left: `calc(${masterData.progres?.current}% - 22px)` }}
            >
              <div className="currentStatusProfile">
                <img src={masterData.profile?.profil_image} alt="" />
              </div>
              <img src="/images/progress-pin.svg" alt="" />
            </div>
            <div className="currentStatusBg">
              <div className="statusLine">
                <img src="/images/status-line.svg" alt="" />
              </div>
              <div className="statusLine">
                <img src="/images/status-line.svg" alt="" />
              </div>
              <div className="statusLine">
                <img src="/images/status-line.svg" alt="" />
              </div>
              <div className="statusLine">
                <img src="/images/status-line.svg" alt="" />
              </div>
              <div className="statusLine">
                <img src="/images/status-line.svg" alt="" />
              </div>
              <div className="statusLine">
                <img src="/images/status-line.svg" alt="" />
              </div>
              <div className="statusLine">
                <img src="/images/status-line.svg" alt="" />
              </div>
              <div className="statusLine">
                <img src="/images/status-line.svg" alt="" />
              </div>
              <div className="statusLine">
                <img src="/images/status-line.svg" alt="" />
              </div>
              <div
                className="statusPercent"
                style={{ width: `calc(${masterData.progres?.current}% - 3px)` }}
              ></div>
            </div>
            <div className="currentStatusCup">
              <img src="/images/cup2.png" alt="" />
            </div>
            <div className="downloadCertificate">
              <button
                onClick={certificateDropdownHandle}
                disabled={(masterData?.certificate ?? []).length === 0}
                // disabled
                data-tooltip={(masterData?.certificate ?? []).length === 0 ?
                  'Certificated can be downloaded after completing a challenge of Hackathon' : ""
                }
              >
                <span>Download Certificate</span>
              </button>
              {certificateDropdown ? (
                <div className="certificateDropMenuWrp">
                  {masterData.certificate.map((data, i) => (
                    <div className="certificateDropMenu" key={i}>
                      <span>{data.name}</span>
                      <a
                        href={data.url}
                        target="_blank"
                        rel="noreferrer"
                        className="btnType1"
                      >
                        Download
                      </a>
                    </div>
                  ))}
                  <div className="closeBtn" onClick={certificateDropdownHandle}>
                    <img src="/images/close-btn.png" alt="" />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="profileDeatilsWrp">
          <div className="profileDeatils">
            <div className="profileImageFrame">
              <img src={masterData.profile?.profil_image} alt="" />
            </div>
            <div className="profileNameWrp">
              <div className="profileName">
                <img src="/images/profile-name-bg.png" alt="" />
                <span>{masterData.profile?.name}</span>
              </div>
              <div className="profileAngleDown" onClick={profileDropdownHandle}>
                {!profileDropdown ? (
                  <img src="/images/profile-dropmenu.png" alt="" />
                ) : null}
              </div>
            </div>
            {profileDropdown ? (
              <div className="profileDropmenuWrp">
                <button
                  className="profileDropmenu"
                  style={{ cursor: 'default' }}
                >
                  {masterData.profile?.course}
                </button>
                <button
                  className="profileDropmenu"
                  style={{ cursor: 'default' }}
                >
                  {masterData.profile?.school_name}
                </button>
                <button
                  className="profileDropmenu"
                  style={{ cursor: 'default' }}
                >
                  {masterData.profile?.parent_name}
                </button>
                <button
                  className="profileDropmenu"
                  style={{ cursor: 'default' }}
                >
                  {masterData.profile?.country}
                </button>
                <button
                  className="profileDropmenu"
                  style={{ cursor: 'default' }}
                >
                  {masterData.profile?.email}
                </button>
                <button
                  className="profileDropmenu"
                  style={{ cursor: 'default' }}
                >
                  {masterData.profile?.phone}
                </button>
                <button className="profileDropmenu" onClick={() => onLogout()}>
                  <img src="/images/logout.png" alt="" /> log Out
                </button>
                <div className="profileDropmenuBtns">
                  <button className="btnType1" onClick={profileDropdownHandle}>
                    OK
                  </button>
                  <button className="btnType2" onClick={profilePopUpFormHandle}>
                    EDIT
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {profilePopUpForm ? (
        <ProfilePopUp
          profilePopUpFormHandle={profilePopUpFormHandle}
          profileDetails={profileDetails}
          inputChange={inputChange}
          postProfileData={postProfileData}
          editEnable={editEnable}
          inputDisable={inputDisable}
          inputError={inputError}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Header;
