import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Overlay, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as animationData from "../lottie/congratulation_badge.json";

const PaymentMsg = (props) => {
  const [showClose, setShowClose] = useState(false);
  const [show, setShow] = useState(false);
  const copyButtonRef = useRef();

  useEffect(() => {
    setTimeout(() => setShowClose(true), 5000);
  }, []);

  const _copyToClipboard = () => {
    setShow(true);

    if (window && window.navigator) {
      window.navigator.clipboard.writeText(props.couponCode);
    }

    setTimeout(() => {
      setShow(false);
    }, 2000);
  };

  return (
    <Modal {...props} size="md" centered>
      <Modal.Body>
        {showClose && 
          <div className="text-end mb-2">
            {/* <img
              src="/images/close.png"
              height={15}
              width={15}
              alt="icode close"
              className="img-fluid fixed btn-close-svg text-black"
              onClick={props.onClose}
              style={{ cursor: "pointer" }}
            /> */}
          </div>
        }
        <Lottie
          isClickToPauseDisabled={true}
          options={{
            animationData,
          }}
          height={230}
        />
        <Overlay target={copyButtonRef.current} show={show} placement="top">
          {(props) => (
            <Tooltip id="overlay-copy" {...props}>
              copied
            </Tooltip>
          )}
        </Overlay>
        {props?.profileDetailsCopy?.country.toLowerCase() == "india" ? <p className="text-center pay-msg">
          <span className="fw-bold fs-5 text-center mb-2">Welcome to the ICode Global Hackathon 2024</span>
          <br />
            <>
              To explore the world of coding and win new challenges, secure your seat today for the world's largest coding competition.  <br />
              Follow the link to complete your registration.
            </>
        </p> : <p className="text-center pay-msg">
          <span className="fw-bold fs-5 text-center mb-2">Welcome</span>
          <br />
            <>
              Enjoy an additional discount on the registration fee. Simply use the exclusive Discount Code{" "}
              <span
                role="button"
                onClick={() => _copyToClipboard()}
                ref={copyButtonRef}
                style={{ cursor: "pointer" }}
                className="fw-bold text-primary"
              >
                {props.couponCode}
              </span>{" "}
              during checkout to redeem your discount. <br />
              Register now! Don't miss this opportunity to secure your spot today!
            </>
          {/* <br />
            Based on your performance in the qualifying round, you've been
            awarded a scholarship. Use the code <span
              onClick={() => _copyToClipboard()}
              ref={copyButtonRef}
              style={{ cursor: "pointer" }}
              className="fw-bold text-primary"
            >
              {props.couponCode}
            </span> during payment to redeem your scholarship. */}
        </p>}
        <div className="text-center mb-3">
          {props?.profileDetailsCopy?.country.toLowerCase() == "india" ? <div>
            <a className="me-3"  href={props.url}>
              <Button variant={Number(props?.practiceData?.attempt?.current) >= 3 ? "success" : "secondary" } >Pay To Enroll</Button>
            </a>
            <Button variant={Number(props?.practiceData?.attempt?.current) >= 3 ? "secondary" : "success"} disabled={Number(props?.practiceData?.attempt?.current) >= 3 } onClick={props.practice}>Experience ICode</Button>
          </div> :
          <a href={props.url}>
            <Button variant="success">Register Now</Button>
          </a>
          }
        </div>
      </Modal.Body>
    </Modal>
  );
};

PaymentMsg.propTypes = {
  url: PropTypes.string,
  couponCode: PropTypes.string,
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

PaymentMsg.defaultProps = {
  show: false,
  url: "",
  couponCode: "",
  onCancel: () => console.log("onCancel.."),
  onSuccess: () => console.log("onSuccess.."),
};

export default PaymentMsg;
