import React from 'react';

const ProfilePopUp = ({
  profilePopUpFormHandle,
  profileDetails,
  inputChange,
  postProfileData,
  editEnable,
  inputDisable,
  inputError,
}) => {
  return (
    <div className="popupWrp">
      <div className="popup">
        <div className="popupHeader">
          <h3>PROFILE</h3>
          <div className="closePopup" onClick={profilePopUpFormHandle}>
            <img src="/images/cross.svg" alt="" />
          </div>
        </div>
        <div className="popupBody">
          <div className="formGroup">
            <div className="formLabel">
              <label>Name</label>
            </div>
            <div className="formInput">
              <input
                type="text"
                name="name"
                id=""
                disabled={inputDisable[0].name}
                value={profileDetails[0].name}
                onChange={(e) => inputChange(e)}
              />
              <button
                type="button"
                onClick={(e) => editEnable(e, 'name')}
                id="name"
              >
                <img src="/images/edit.png" alt="" />
              </button>
            </div>
            <div className="errorMsg">{inputError[0].name}</div>
          </div>
          <div className="formGroup">
            <div className="formLabel">
              <label>School Name</label>
            </div>
            <div className="formInput">
              <input
                type="text"
                name="school-name"
                id=""
                disabled={inputDisable[0].school_name}
                value={profileDetails[0].school_name}
                onChange={(e) => inputChange(e)}
              />
              {/* <button type='button' onClick={(e)=>editEnable(e, 'school-name')} id='school-name'><img src='/images/edit.png' alt=''/></button> */}
            </div>
            <div className="errorMsg">{inputError[0].school_name}</div>
          </div>
          <div className="formGroup">
            <div className="formLabel">
              <label>Parent Name</label>
            </div>
            <div className="formInput">
              <input
                type="text"
                name="parent-name"
                id=""
                disabled={inputDisable[0].parent_name}
                value={profileDetails[0].parent_name}
                onChange={(e) => inputChange(e)}
              />
              <button
                type="button"
                onClick={(e) => editEnable(e, 'parent-name')}
                id="parent-name"
              >
                <img src="/images/edit.png" alt="" />
              </button>
            </div>
            <div className="errorMsg">{inputError[0].parent_name}</div>
          </div>
          <div className="formGroup">
            <div className="formLabel">
              <label>Country</label>
            </div>
            <div className="formInput">
              <input
                type="text"
                name="country"
                id=""
                disabled={inputDisable[0].country}
                value={profileDetails[0].country}
                onChange={(e) => inputChange(e)}
              />
              {/* <button type='button' onClick={(e)=>editEnable(e, 'country')} id='country'><img src='/images/edit.png' alt=''/></button> */}
            </div>
            <div className="errorMsg">{inputError[0].country}</div>
          </div>
          <div className="formGroup">
            <div className="formLabel">
              <label>Number</label>
            </div>
            <div className="formInput">
              <input
                type="number"
                name="number"
                id=""
                disabled={inputDisable[0].phone}
                value={profileDetails[0].phone}
                onChange={(e) => inputChange(e)}
              />
              <button
                type="button"
                onClick={(e) => editEnable(e, 'number')}
                id="number"
              >
                <img src="/images/edit.png" alt="" />
              </button>
            </div>
            <div className="errorMsg">{inputError[0].phone}</div>
          </div>
          <div className="formGroup">
            <div className="formLabel">
              <label>Upload Image</label>
            </div>
            <div className="formInput">
              <input
                type="file"
                name="upload"
                id="upload"
                onChange={(e) => inputChange(e)}
              />
              {/* <button type='button'><img src='/images/edit.png' alt=''/></button> */}
            </div>
          </div>
          <div className="formGroup formSubmit">
            <button type="submit" onClick={postProfileData}>
              <img src="/images/save.png" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePopUp;
