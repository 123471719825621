import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import './App.css';
import Header from './Components/Header';
import { hostName } from './Api/API_HOST';
import Data from './Api/API.json';
import PaymentMsg from './Components/PaymentMsg';
import { Button } from 'react-bootstrap';

const App = () => {
	axios.defaults.withCredentials = true;

	const history = createBrowserHistory();

	const [interval, setInterval] = useState('');
	const [show, setShow] = useState(false);
	const [paymentUrl, setPaymentUrl] = useState('');
	const [couponCode, setCouponCode] = useState('');
	const [masterData, setMasterData] = useState([]);
	const [certificateDropdown, setCertificateDropdown] = useState(false);
	const [profileDropdown, setProfileDropdown] = useState(false);
	const [moreConceptDropdown, setMoreConceptDropdown] = useState(false);
	const [profilePopUpForm, setProfilePopUpForm] = useState(false);
	const [loader, setLoader] = useState(false);
	const [conceptVideo, setConceptVideo] = useState('');
	const [conceptGame, setConceptGame] = useState(0);
	const [profileDetails, setProfileDetails] = useState();
	const [profileDetailsCopy, setProfileDetailsCopy] = useState();
	const [userCountry, setUserCountry] = useState("india");
	const [file, setFile] = useState();
	const [inputDisable, setInputDisable] = useState([
		{
			name: true,
			school_name: true,
			parent_name: true,
			country: true,
			phone: true,
		},
	]);
	const [inputError, setInputError] = useState([
		{
			name: '',
			school_name: '',
			parent_name: '',
			country: '',
			phone: '',
		},
	]);
	const editEnable = (e, name) => {
		let changeData = [...inputDisable];
		if (name === 'name') {
			changeData[0].name = false;
		}
		if (name === 'school-name') {
			changeData[0].school_name = false;
		}
		if (name === 'parent-name') {
			changeData[0].parent_name = false;
		}
		if (name === 'country') {
			changeData[0].country = false;
		}
		if (name === 'number') {
			changeData[0].phone = false;
		}
		setInputDisable(changeData);
		// console.log(changeData)
	};
	const inputChange = (e) => {
		let changeData = [...profileDetails];
		// const inputName = e.target.name;
		if (e.target.name === 'name') {
			// const newName = e.target.value.replace(
			//	 /[^a-zA-Z\s]/g,
			//	 ""
			// );
			changeData[0].name = e.target.value.replace(/[^a-zA-Z\s]/g, '');
		}
		if (e.target.name === 'school-name') {
			changeData[0].school_name = e.target.value.replace(/[^a-zA-Z\s]/g, '');
		}
		if (e.target.name === 'parent-name') {
			changeData[0].parent_name = e.target.value.replace(/[^a-zA-Z\s]/g, '');
		}
		if (e.target.name === 'country') {
			changeData[0].country = e.target.value.replace(/[^a-zA-Z\s]/g, '');
		}
		if (e.target.name === 'number') {
			changeData[0].phone = e.target.value;
		}
		if (e.target.name === 'upload') {
			setFile(e.target.files[0]);
			console.log(e.target.files[0]);
		}
		setProfileDetails(changeData);
		// let changeData = [...profileDetails]
		// console.log(Array.isArray(changeData), 'profileDetails')
	};
	const profileDropdownHandle = (e) => {
		e.preventDefault();
		setProfileDropdown(!profileDropdown);
	};
	const certificateDropdownHandle = (e) => {
		e.preventDefault();
		setCertificateDropdown(!certificateDropdown);
	};
	const moreConceptDropdownHandle = (e) => {
		e.preventDefault();
		setMoreConceptDropdown(!moreConceptDropdown);
	};
	const profilePopUpFormHandle = (e) => {
		let copy = JSON.parse(JSON.stringify(profileDetailsCopy));
		e.preventDefault();
		setProfilePopUpForm(!profilePopUpForm);
		// console.log(copy)
		setProfileDetails(copy);
		setProfileDropdown(false);
	};
	const conceptVideoHandle = (data) => {
		let urlName = data?.url ?? '';
		let urlSplit = urlName.split('/');
		setConceptVideo(urlSplit[3]);
		setMoreConceptDropdown(false);
		setConceptGame(data?.id ?? 0);
	};
	const getEventDetails = async () => {
		setLoader(true);
		await axios
			.get(`${hostName}/gameDashboardAPI`)
			.then((response) => {
				if (
					!(
						response &&
						response.data &&
						response.data.learning_concept &&
						response.data.learning_concept[0] &&
						response.data.learning_concept[0].url
					)
				) {
					window.location.href = `https://2024.icode.org/`;
				}
				let urlName = response.data.learning_concept[0].url;
				let urlSplit = urlName.split('/');
				setMasterData(response.data);
				setConceptVideo(urlSplit[3]);
				setUserCountry(response?.data?.profile?.country)
				setProfileDetails([response.data.profile]);
				setProfileDetailsCopy([response.data.profile]);
				setLoader(false);
				response.data?.register && setShow(true);
				response.data?.register && setPaymentUrl(response.data?.register)
				response.data?.coupon_code && setCouponCode(response.data?.coupon_code)
			})
			.catch((err) => {
				setLoader(false);
				alert(err?.response?.data?.error ?? '');
				console.log(err);
				console.log(err.status);

				if (err?.response?.data?.login) {
					window.location.href = `https://2024.icode.org/`;
				}
			});
	};

	const postProfileData = async (e) => {
		if (profileDetails[0].name === '') {
			setInputError([
				{
					name: 'Name Can not be blank',
					school_name: '',
					parent_name: '',
					country: '',
					phone: '',
				},
			]);
			return false;
		}
		if (profileDetails[0].school_name === '') {
			setInputError([
				{
					name: '',
					school_name: 'School Name Can not be blank',
					parent_name: '',
					country: '',
					phone: '',
				},
			]);
			return false;
		}
		if (profileDetails[0].parent_name === '') {
			setInputError([
				{
					name: '',
					school_name: '',
					parent_name: 'Parent Name Can not be blank',
					country: '',
					phone: '',
				},
			]);
			return false;
		}
		if (profileDetails[0].country === '') {
			setInputError([
				{
					name: '',
					school_name: '',
					parent_name: '',
					country: 'Parent Name Can not be blank',
					phone: '',
				},
			]);
			return false;
		}
		if (profileDetails[0].phone.length > 14) {
			setInputError([
				{
					name: '',
					school_name: '',
					parent_name: '',
					country: '',
					phone: 'Please input valid Phone number',
				},
			]);
			return false;
		}
		setLoader(true);
		let profileData2 = new FormData();
		if (file === undefined) {
			profileData2.append('profil_image', profileDetails[0].profil_image);
		}
		profileData2.append('profil_image', file);
		profileData2.append('name', profileDetails[0].name);
		profileData2.append('school_name', profileDetails[0].school_name);
		profileData2.append('parent_name', profileDetails[0].parent_name);
		profileData2.append('country', profileDetails[0].country);
		profileData2.append('phone', profileDetails[0].phone);

		await axios
			.post(`${hostName}/eventUpdateProfile`, profileData2)
			.then((response) => {
				window.location.reload();
				setProfilePopUpForm(false);
				setLoader(false);
				setInputError([
					{
						name: '',
						school_name: '',
						parent_name: '',
						country: '',
						phone: '',
					},
				]);
				setInputDisable([
					{
						name: true,
						school_name: true,
						parent_name: true,
						country: true,
						phone: true,
					},
				]);
			})
			.catch((err) => {
				console.log(err);
				console.log(err.status);
				alert(err?.response?.data?.error ?? '');
				if (err?.response?.data?.login) {
					window.location.href = `https://2024.icode.org/`;
				}
			});
	};

	useEffect(() => {
		getEventDetails();
		if (!window.history || !window.history.length) {
			window.location.href = 'https://2024.icode.org/'; // replace with your desired URL
		}
	}, []);

	const _onLogout = async () => {
		setLoader(true);
		await axios
			.get(`${hostName}/logout`)
			.then((response) => {
				console.log({ response });
				if (response.data?.redirect) {
					window.location = response.data?.redirect;
				}
				setLoader(false);
			})
			.catch((err) => {
				setLoader(false);
				alert(err?.response?.data?.error ?? '');
				console.log(err);
				console.log(err.status);
			});
	};

	const _enterPractice = async (id = 0) => {
		setLoader(true);
		await axios
			.get(`${hostName}/enterPractice/${id}`)
			.then((response) => {
				console.log({ response });
				if (response.data?.redirect) {
					window.location = response.data?.redirect;
				}
				setLoader(false);
			})
			.catch((err) => {
				setLoader(false);
				alert(err?.response?.data?.error ?? '');
				console.log(err);
				console.log(err.status);
				if (err?.response?.data?.login) {
					window.location.href = `https://2024.icode.org/`;
				}
			});
	};

	const _onJoinWeeklyChallenge = async (id = 0) => {
		setLoader(true);
		await axios
			.get(`${hostName}/joinWeeklyChallenge/${id}`)
			.then((response) => {
				console.log({ response });
				if (response.data?.redirect) {
					window.location = response.data?.redirect;
				}
				setLoader(false);
			})
			.catch((err) => {
				setLoader(false);
				alert(err?.response?.data?.error ?? '');
				console.log(err);
				console.log(err.status);
				if (err?.response?.data?.login) {
					window.location.href = `https://2024.icode.org/`;
				}
			});
	};

	const _onJoinHackathon = async (id = 0) => {
		setLoader(true);
		await axios
			.get(`${hostName}/joinHackathon/${id}`)
			.then((response) => {
				console.log({ response });
				if (response.data?.redirect) {
					window.location = response.data?.redirect;
				}
				setLoader(false);
			})
			.catch((err) => {
				setLoader(false);
				alert(err?.response?.data?.error ?? '');
				console.log(err);
				console.log(err.status);
				if (err?.response?.data?.login) {
					window.location.href = `https://2024.icode.org/`;
				}
			});
	};

	const upcomingEvent = () => (
		<div className="upcomingEvents">
							<div className="sectionHeading">
								<span className="headingFrame">
									<span>Upcoming Events</span>
								</span>
							</div>
							<div className="eventsList">
								{masterData.upcomingEvent?.map((event, i) => (
									<div className="eventRow" key={i}>
										<h3>{event.from_date}</h3>
										<button
											className="eventBtn"
											disabled={event.join}
											data-tooltip={
												'The Live Hackathon will open on mentioned date.'
											}
											onClick={() => _onJoinHackathon(event?.id ?? 0)}
										>
											{event.name}
										</button>
									</div>
								))}
							</div>
						</div>
	)

	return (
		<React.Fragment>
			<div className="appWrp">
				<Header
					profilePopUpForm={profilePopUpForm}
					profileDropdown={profileDropdown}
					profileDropdownHandle={profileDropdownHandle}
					profilePopUpFormHandle={profilePopUpFormHandle}
					certificateDropdown={certificateDropdown}
					certificateDropdownHandle={certificateDropdownHandle}
					masterData={masterData}
					profileDetails={profileDetails}
					userCountry={userCountry}
					inputChange={inputChange}
					postProfileData={postProfileData}
					editEnable={editEnable}
					inputDisable={inputDisable}
					inputError={inputError}
					onLogout={_onLogout}
				/>

				<div className="midSection w-100 gap-3">
					<div className="leftSidebar w-100">
						<div className="importantUpdates">
							<div className="sectionHeading">
								<span className="headingFrame">
									<span>Important Updates</span>
								</span>
							</div>
							<div className="importantUpdatesContent">
								Welcome to ICode Global Hackathon, your learning partner for coding education.
								You and I will together explore the Space and you will help me
								navigate by writing different codes. As you learn and write
								codes, we will be able to move to new stages of our journey. As
								we move ahead, you will be able to collect points and energy
								coins
							</div>
							<div className="importantUpdatesDate">
								<div className="updateDateHeader">
									<div className="updateDate">Date</div>
									<div className="updateCon">Update</div>
								</div>
								<div className="UpdateContentWrp">
									{masterData.importantUpdate?.map((update, i) => (
										<div className="UpdateRow" key={i}>
											<div className="updateDate">{update.date}</div>
											<div className="updateCon">
												<p
													dangerouslySetInnerHTML={{__html: update.message}}
												/>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
						{upcomingEvent()}
					</div>
					<div className="midSectionWrp w-100">
						<div className="videoWrp">
							<h1>Self - learning part</h1>
							<div className="videoContainer">
								<div className="videoHeading">Learn Concept</div>
								<div className="videoBx">
									<img src="/images/video-bx.png" alt="" />
									<div className="videoUrl">
										<iframe
											width="100%"
											height="185"
											src={`https://www.youtube.com/embed/${conceptVideo}`}
											title="YouTube video player"
											frameBorder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
											allowFullScreen
										></iframe>
									</div>
									<div className="videoBtns">
										<div className="conceptBtn">
											<button
												className="btnType1"
												onClick={moreConceptDropdownHandle}
											>
												More
												<br />
												Concepts
											</button>
											{moreConceptDropdown ? (
												<div className="conceptDropMenuWrp">
													{masterData.learning_concept?.map((concept, i) => (
														<div className="conceptDropMenu" key={i}>
															<button
																value={concept.url}
																onClick={(e) => conceptVideoHandle(concept)}
															>
																{concept.name}
															</button>
														</div>
													))}
												</div>
											) : null}
										</div>
										{/* <button
											className="videoBtn2"
											onClick={() => !show &&_enterPractice(conceptGame)}
											disabled={show}
											data-tooltip={
												'Select a concept from list to start practising'
											}
										>
											Practice Concept
										</button> */}
										{/* <button
											className="btnType1"
											onClick={() => !show && _enterPractice(0)}
											disabled={show}
										>
											Practice all
											<br />
											Concepts
										</button> */}
										<button
											className="btnType1"
											onClick={() => !show &&_enterPractice(conceptGame)}
											disabled={show}
											// data-tooltip={
											// 	'Select a concept from list to start practising'
											// }
											title='Select a concept from list to start practising'
										>
											Practice <br /> Concept
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-4'>
						{/* {userCountry.toLowerCase() !== "india" ? : <>{upcomingEvent()}</>}*/}
						<div className="rightSideBar w-100">
							<h2>CHALLENGES</h2>
							<div className="hackathonTable">
								<div className="hackathonTableWrp">
									<div className="hackathonTableHeadingRow">
										<div className="hackathonTableCell">Qualifier Challenges</div>
										{/* <div className="hackathonTableCell">Date</div> */}
										<div className="hackathonTableCell">Score</div>
										<div className="hackathonTableCell">Attempts</div>
									</div>
									{masterData.hackathon?.map((data, i) => (
										<>{data.name.toLowerCase() !== "practice challenge" &&<div className="hackathonTableBodyRow" key={i}>
											<div className="hackathonTableBodyCell">
												<h4>{data.name}</h4>
												<button
													disabled={data.join}
													data-tooltip={
														'Click to participate in Qualifier Challenge.'
													}
													onClick={() => _onJoinWeeklyChallenge(data?.id ?? 0)}
												>
													START
												</button>
											</div>
											{/* <div className="hackathonTableBodyCell">
												<h4>{data.date}</h4>
											</div> */}
											<div className="hackathonTableBodyCell">
												<h4>
													{data.score.current}/{data.score.total}
												</h4>
											</div>
											<div className="hackathonTableBodyCell">
												<h4>
													<span>{data.attempt.current}</span>
												</h4>
											</div>
										</div>}
										</>
									))}
								</div>
							</div>
						</div> 
						
					</div>
				</div>
			</div>
			<div className="footer">
				<a href="https://icode.org/contact/" target="_blank" rel="noreferrer">
					Contact Us <img src="/images/contact.png" alt="" />
				</a>
			</div>
			{loader ? (
				<div className="loader">
					<img src="/images/loading-gif.gif" alt="" />
				</div>
			) : null}

			<PaymentMsg
				show={show}
				url={paymentUrl}
				couponCode={couponCode}
				onClose={() => setShow(false)}
				profileDetailsCopy={masterData?.profile}
				practiceData = {masterData?.hackathon?.filter(item => item?.name?.toLowerCase() == "practice challenge")[0]}
				practice = {() => _onJoinWeeklyChallenge(masterData?.hackathon?.filter(item => item?.name?.toLowerCase() == "practice challenge")[0]?.id)}
			/>
		</React.Fragment>
	);
};
export default App;
